// 计算出时间戳的具体数据：比如将85400转化为 n时n分n秒
export function formateTimeStamp (timeStamp) {
  var day;
  var hour;
  var min;
  var seconds;

  const nowTimestamp = new Date().getTime()/1000;
  const leftTimestamp = timeStamp - nowTimestamp;
  day = parseInt(leftTimestamp / (60 * 60 * 24))
  hour = parseInt((leftTimestamp-day * 24 * 60 * 60) / (60 * 60)) // 计算整数小时数
  min = parseInt((leftTimestamp - day * 24 * 60 * 60 - hour * 3600) / 60) // 计算整数分
  seconds = parseInt(leftTimestamp % 60) // 取得算出分后剩余的秒数
  // if (hour < 10) {
  //   hour = '0' + hour
  // }

  // if (min < 10) {
  //   min = '0' + min;
  // }

  // if (seconds < 10) {
  //   seconds = '0' + seconds;
  // }

  const restTime = {
    day:day,
    hour: hour,
    min: min,
    seconds: seconds
  }
  return restTime
}
